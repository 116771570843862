export const enTranslations = {
    navbar: {
        restaurant_location: "Navigate to the place",
        make_reservations: "Book a table",
        search_product: "Search product",
        call_waiter: "Call waiter",
        change_language: "Change language",
        feedback: "Feedback",
        about_tabl: 'About TABL',
    },

    pages: {
        products: {
            choose: "Choose",
            choose_ingredients: "Choose ingredients",
            choose_variant: "Choose variant",
            alergens: "Allergens",
            out_of_stock: "Out of stock",
            variant_not_choosed: "Product variant is not selected!",
            min_count_addable_ingredients_reached: "You have to choose at least {value} ingredients!",
            max_count_addable_ingredients_reached: "You cant choose more than {value} ingredients!",
            additional_options: "Additional options",
            addable_options: "Add", // or: Add ingredients
            removable_options: "Remove",
            comment: "Product comment", // Or:  Add extra chili please...
            related_products: "Related products",
            add: "Add", // in context 'add to cart'
            add_to_cart: "Add to cart", // in context 'add to cart'
            choose_size: "Choose",
            save_for_order: "Save for ordering",
            order_disabled: "Currently this product cant be ordered.",
            book: "Book"
        },
        change_language: {
            title: "Please choose a language",
        },
        call_waiter: {
            clean_table: "Please clean the table",
            new_cutlery: "Please change the cutlery",
            more_napkins: "More napkins please",
            change_ashtray: "Please change the ashtray",
            bring_ice: "Please bring ice",
            other: "Other",
        },
        feedback: {
            title: "We will be happy if you rate us",
            customer_reviews: "Customer reviews",
            no_reviews_yet: "Be the first one to rate this service."
        },
        about_tabl: {
            title: "This service reaches you thanks to ",
            sub_title: "If you need more information about this system, or recommendations about TabL, please contact us.",
            about_link: "https://tabl.bg/"
        },
        cart_delivery: {
            minimal_order_price: "Minimal price for order is {value}",
            price_according_to_region: "Price according to region",
            payment_type_room: "Choose payment type",
            payment_type_pickup: "Choose pickup payment type",
            payment_type_delivery: "Choose delivery payment type",
            cutlery_needed: "Did you need cutlery?",
            how_many_cutlery_needed: "How many cutleries you need?",
            cutlery_needed_yes: "Yes, please",
            cutlery_needed_no: "No, thanks",
            region: "Region",
            // you_can_order_between: "Може да поръчате от {from} до {to}",
            call_waiter_to_order: "Please call the waiter to order",
            you_can_order_between: "Ordering hours: {from} - {to} and {from_two} - {to_two}",
            restaurant_closed_label: "We are closed, thank you for your patience!",
            restaurant_closed_label_day: "We are closed, try again {day}: {value}",
            restaurant_closed_label_tommorow: "We are closed, try again tommorow at {value}",
            restaurant_closed_label_today: "We are closed, try again at {value}",
            delivery_price: "Delivery price",
            delivery_price_is_not_included: "+ delivery price",
            cart: "Cart",
            no_cart_items: "Your cart is empty.",
            orders: "Orders",
            order: "Order",
            min_delivery_price_text: 'For orders over {value} delivery is free',
            no_order_items: "You don't have active orders.",
            total: "Price",
            total_bill: "Total",

            status_ordered: 'Accepted',
            status_cooked_spot: 'Cooked',
            status_cooked_delivery: 'Cooked',
            status_cooked_preorder: 'Waiting pickup',
            status_served_spot: 'Served',
            status_on_the_way_delivery: 'Delivering',
            status_paid: 'Finished',
            rejected: 'Rejected',

            make_order: "Order",
            make_order_simple: "Order",
            want_the_bill: "Ask for BILL",
            finish_bill: "Finish the BILL",
            pay_in_cash: "Pay in cash",
            pay_with_card: "Pay with card",
            make_delivery_order: "Order with delivery",
            choose_order_type: "Choose order type",
            choose_payment_type: "Choose payment type",
            payment_cash: "In cash",
            payment_card: "With a card",
            payment_epay: "Online",
            payment_online: "Online",
            payment_cash_delivery: "In cash",
            payment_card_delivery: "With a card when delivered",
            payment_epay_delivery: "Online",
            payment_online_delivery: "Online",
            payment_on_room_bill: "On room bill",
            delivery_price_included_in_total_price: "Delivery price included: {delivery_price_num} /For orders up to {min_price_free_delivery_num}/",
            order_type_restaurant: "In restaurant",
            scan_barcode: "Scan the QR code",
            order_type_delivery: "Door Delivery",
            order_type_preorder: "Address Pick up",
            order_type_room: "Order from room",
            order_type_served_on_spot: "Order for served on spot",
            first_and_last_name: "Name",
            phone: "Phone",
            order_address: "Address, street, town",
            delivery_time: "Date and Time for pickup",
            delivery_address: "Delivery address",
            room_number: "Room number",
            table: "Table",
            room: "Room",
            email: "Email",
            remember_me: "Remember me",
            promo_code: "Promocode",
            promo_code_placeholder: "Promocode...",
            apply: "Apply",
            cancel: "Cancel",
            bill_already_wanted: "/bill already wanted/",
            clear: "Clear",
            close: "Close",
            save: "Save",
            save_and_add: "Save & Add",
            fast_order: "Fast order",
            in_the_region_delivery: "I am in the area of {region}",
            no_saved_addresses_with_that_name: 'No saved addressed with that name, add it from "New address"',
            choose_pickup_place: "Choose pickup restaurant",
            choose_pickup_place_label: "Choose restaurant",
            served_on_spot: "Served on spot",
            choose_address: "Choose address",
            add_new_address: "New address",
            choose_delivery_address: "Choose delivery address",
            type_address: 'Start typing your address',
            floor_and_appartment: "Floor./ap.",
            floor_short: "Fl.",
            appartment_short: "Ap.",
            street_number_short: "№/Bl.",
            bell_short: 'Bell',
            street_number: "Number",
            entrance_short: 'E-ce.'
        },
        search: {
            searchProduct: "Search a product",
            noSearchResults: "No products found",
            noSearchDescription: "Please change the criteria, to found the right product for you!",
        },
        choose_place: {
            title: "Pleace choose a place"
        },
        promotions: {
            title: "Promotion"
        },
        profile: {
            title: "My profile",
            fullname: "Names",
            first_name: "First name",
            last_name: "Last name",
            email: "Email",
            phone: "Phone",
            address: "Address",
            change_password: "Change password",
            password: "Password",
            new_password: "New Password",
            tabl_points: "TabL points",
            tabl_points_info: "Collect TabL points with every order and be ready for awesome surprizes ;)",
            my_profile: "My profile",
            profile: "Profile",
            logout: "Logout",
            orders: "My orders",
            set_waiter_table: "Set table for order",
            change_waiter_table: "Change table for order",
        },
        my_orders: {
            title: "My orders",
            order_date: "Order date",
            products: "Products",
            type: "Type",
            amount: "Amount",
            payment: "Payment",
            status: "Status",
            address: "Address",
            personal_data: "Personal data"
        },
        booking: {
            book: "Reserve",
            choose_time: "Choose date and hour for booking",
            minutes: "min.",
            success_booking: "Успешна резервация. Ще получите мейл с потвърждение. Регистрирайте се и ще можете да следите резервациите си ;)"

        }
    },
    orders: {
        statuses: {
            ordered: "Поръчана",
            cooked_spot: 'Приготвена',
            served_spot: 'Сервирана',
            cooked_delivery: 'Приготвена',
            on_the_way_delivery: 'Доставя се',
            cooked_preorder: 'Приготвена',
            cooked_room: "Приготвена",
            paid: "Платена",
        },
        paid_online: "Paid online",
    },
    common: {
        hi: "Hello",
        home: "Home",
        free_del: "Free",
        amount: "Amount",
        subtotal: "Subtotal",
        final_price: "Final price",
        no_internet: "No internet connection.",
        count: 'pc.',
        box: "box",
        like_us: "Like us",
        follow_us: "Follow us",
        change_place: 'Change place',
        current_place: 'Current place',
        youtube: "YouTube",
        tipadvisor: "Tripadvisor",
        about: 'About',
        about_us: 'About us',
        discount: 'Discount',
        personal_discount: 'Personal discount',
        discount_promocode: 'Discount Promocode',
        delivery: 'Delivery',
        book: 'Book',
        language: 'Language',
        navigate_me: "Navigete",
        navigation: 'Directions',
        search: 'Search',
        call_us: 'Call us',
        options: 'Options',
        open: 'Open',
        product: "product",
        products: "products",
        user: "User",
        comment: "comment",
        contacts: "Contacts",
        choose: 'Please choose',
        working_hours: "Working hours",
        payment_methods: "Payment methods",
        gallery: "Gallery",
        change_table: "Change table",
        clear_cart: "Clear",
        modify_ingredients: "Change ingredients",
        boxes_prices: "Box",
        refund_policy: "Refund policy",
        date: {
            today: "Today",
            tommorow: "Tommorow",
            day: {
                1: 'Monday',
                2: 'Tuesday',
                3: 'Wednesday',
                4: 'Thursday',
                5: 'Friday',
                6: 'Saturday',
                7: 'Sunday',
            }
        },

        action: {
            send: "Send",
            skip: "Skip",
            back: "Back",
        },

        label: {
            describe_here: "Type here",
        },

        price_with_discount: "Price with discount",
        subscribe_for_lunch_menu: "Subscribe for lunch menu",
        cart: "Cart",
        profile: "Profile",
        login_or_register: "Login / Register",
        close: "Close",
        terms_for_use: "Terms and conditions",
        privacy_policy: "Privacy policy",
        i_read_and_agree_with: "I've read and agree with ",
        i_agree_with: "I agree with",
        currency_bgn: " BGN",
        add_btn: "Add",
        order_comment: "Order comment",
        order_modal_agree_with: "Making this order you agree with our ",
        and: ' and ',
        cutleries: 'Cutleries',
        save: "Save"
    },

    messages: {
        notifications: {
            waiter_notified_successfully: 'The waiter was notified successfully',
            order_sent_success: "Your order is being processed. Thank you!",
            feedback_thanks: "Thank you! We appreciate it.",
            bill_wanted_success: 'We have received your payment request.',
            user_need_success: 'We received your request.',
            create_order_first: "You first need to create an order.",
            bill_already_wanted: "Your already asked for the bill",
            reset_password_success: "If profile with this email exists, you will receive instructions to change your password"
        },
        errors: {
            invalid_privacy_policy_and_terms: "Please accept the terms and the privacy policy",
            invalid_privacy_policy: "Please accept the privacy policy",
            invalid_terms: "Please accept the terms",
            invalid_email: 'Please enter valid Email',
            invalid_password: 'Please enter valid Password',
            invalid_name: 'Please enter valid Name (Between 3 and 40 symbols)',
            invalid_phone: 'Please enter valid Phone number',
            invalid_address: 'Please enter valid Address',
            invalid_regionId: 'Please select Region',
            invalid_orderDate: 'Please select Order date',
            invalid_orderTime: 'Please select Order time',
            invalid_room_number: 'Please select Room number',
            invalid_payment_method: 'Please select Payment method',
            invalid_cutlery_selected: "Please select cutlery",
            invalid_additional_order_type: "Please choose order type",
            invalid_text_comment: "Please enter valid comment up to 500 symbols",
            invalid_fields: "Please fill in all fields",
            order_sent_fail: "You have scanned invalid QR code.",
            bill_wanted_fail: "Something went wrong during your payment request.",
            user_need_fail: 'Something went wrong duringsending request.',
            "Too many requests, please try again later.": "Too many requests, please try again later",
            "Invalid phone": "Invalid phone",
            "Invalid name": "Invalid name",
            error: "Error",
            invalid_street_number: "Please, enter a valid street number",
            invalid_street_number_length: "Number should not be longer than 10 characters",
            invalid_required_checkbox_in_order_modal: "Please agree if you want to proceed",
            invalid_required_checkbox_in_order_modal_2: "Please agree if you want to proceed"
        }
    },

    modals: {
        upselling_products: {
            title: "Want to try?"
        },
        language_modal: {
            back_to_default_lang: "Back to default language",
            translations_may_not_be_accurate: "Translations may not be accurate"
        },
        initial_html_banner: {
            title: "Welcome",
            to_the_menu: "To the menu"
        }
    },

    promocode: {
        others_discounts_are_not_applied: "When using this promo code, all other discounts are not applied"
    },

    payments: {
        choose_payment_method: "Choose payment method",
        processing: "Payment is being processed"
    },
    login: {
        log_in_your_profile: "Login",
        save_your_data: "and save your data",
        login_btn: "Login",
        register_if_no_profile: "Still no profile? Register",
        forgot_password: "Forgot password"
    },
    register: {
        register: "Register",
        collect_tabl_points: "and collect TabL points",
        register_btn: "Register",
        already_have_account: "Already have an account? Log in now"
    },
    forgot_password: {
        forgot_password: "Forgot password",
    },
    accumulated_order_amount: {
        use: "Use",
        remove: "Remove",
        register_for_discount: "Register and earn money with each order",
        you_have_accumulated: "You have",
        no_accumulated_money_text: "Order and save money for your next order",
        register_and_accumulate: "Register and get",
        for_you_next_order: "for your next order",
        complete_your_order_and_accumulate: "Complete your order and earn",
        profile_text: "Accumulated money from orders",
        profile_description: "With each order, a % of the amount is accumulated in your account. You can use it on your next purchase :)"
    }
}