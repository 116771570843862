export const esTranslations = {
    "navbar": {
        "restaurant_location": "Dirección al restaurante",
        "make_reservations": "Hacer una reserva",
        "search_product": "Buscar producto",
        "call_waiter": "Llamar al camarero",
        "change_language": "Cambiar idioma",
        "feedback": "Valóranos",
        "about_tabl": "Acerca de TABL"
    },
    "pages": {
        "products": {
            "choose": "Elegir",
            "choose_ingredients": "Elegir ingredientes",
            "choose_variant": "Por favor elige",
            "alergens": "Alérgenos",
            "out_of_stock": "No disponible",
            "variant_not_choosed": "¡No has elegido una variante!",
            "min_count_addable_ingredients_reached": "¡Debes elegir al menos {value} complementos!",
            "max_count_addable_ingredients_reached": "¡No puedes elegir más de {value} complementos!",
            "additional_options": "Opciones adicionales",
            "addable_options": "Complementos",
            "removable_options": "Eliminar",
            "comment": "Comentario sobre el producto",
            "related_products": "Otras sugerencias",
            "add": "Añadir",
            "add_to_cart": "Añadir al carrito",
            "choose_size": "Elige",
            "save_for_order": "Guardar para el pedido",
            "order_disabled": "¡Este producto no se puede pedir en este momento!",
            "book": "Reservar"
        },
        "change_language": {
            "title": "Por favor elige un idioma"
        },
        "call_waiter": {
            "clean_table": "Por favor, limpie la mesa",
            "new_cutlery": "Por favor, traiga nuevos cubiertos",
            "more_napkins": "Por favor, traiga más servilletas",
            "change_ashtray": "Por favor, cambie el cenicero",
            "bring_ice": "Por favor, traiga hielo",
            "other": "Otro"
        },
        "feedback": {
            "title": "Tómate un minuto para valorarnos",
            "customer_reviews": "Opiniones de clientes",
            "no_reviews_yet": "Sé uno de los primeros en valorar este servicio."
        },
        "about_tabl": {
            "title": "Este servicio llega a usted gracias a ",
            "sub_title": "Si necesitas más información sobre la plataforma TabL, por favor contáctanos.",
            "about_link": "https://tabl.bg/"
        },
        "cart_delivery": {
            "minimal_order_price": "El precio mínimo para pedidos a domicilio es {value}",
            "price_according_to_region": "Precio según la región",
            "payment_type_room": "Método de pago al servir",
            "payment_type_pickup": "Método de pago al recoger",
            "payment_type_delivery": "Método de pago en la entrega",
            "cutlery_needed": "¿Desea cubiertos?",
            "how_many_cutlery_needed": "¿Cuántos cubiertos necesita?",
            "cutlery_needed_yes": "Sí, por favor",
            "cutlery_needed_no": "No, gracias",
            "region": "Región",
            "call_waiter_to_order": "Por favor, llame al camarero para hacer su pedido",
            "you_can_order_between": "Puede hacer un pedido de {from} a {to} y de {from_two} a {to_two}",
            "restaurant_closed_label": "No estamos aceptando pedidos en este momento. ¡Gracias por su comprensión!",
            "restaurant_closed_label_day": "No estamos aceptando pedidos en este momento, por favor inténtelo de nuevo {day}: {value}",
            "restaurant_closed_label_tommorow": "No estamos aceptando pedidos en este momento, por favor inténtelo de nuevo mañana a las {value}",
            "restaurant_closed_label_today": "No estamos aceptando pedidos en este momento, por favor inténtelo más tarde a las {value}",
            "delivery_price": "Precio de entrega",
            "delivery_price_is_not_included": "+ precio de entrega",
            "cart": "Carrito",
            "no_cart_items": "No hay productos en el carrito",
            "orders": "Pedidos",
            "order": "Pedido",
            "min_delivery_price_text": "Para pedidos superiores a {value}, la entrega es gratuita",
            "no_order_items": "No tiene pedidos activos en este momento",
            "total": "Precio total",
            "total_bill": "Total",
            "status_ordered": "Aceptado",
            "status_cooked_spot": "Preparado",
            "status_cooked_delivery": "Preparado",
            "status_cooked_preorder": "Esperando al cliente",
            "status_served_spot": "Servido",
            "status_on_the_way_delivery": "En camino",
            "status_paid": "Completado",
            "rejected": "Rechazado",
            "pay_in_cash": "Pagar en efectivo",
            "pay_with_card": "Pagar con tarjeta",
            "make_order": "Hacer pedido",
            make_order_simple: "Hacer pedido",
            "want_the_bill": "Pedir la cuenta",
            "finish_bill": "Finalizar la cuenta",
            "make_delivery_order": "Pedir con entrega",
            "choose_order_type": "Elige el tipo de pedido",
            "choose_payment_type": "Elige el método de pago",
            "payment_cash": "En efectivo",
            "payment_card": "Con tarjeta",
            "payment_epay": "En línea",
            payment_online: "Tarjeta",
            "payment_cash_delivery": "En efectivo al recibir",
            "payment_card_delivery": "Con tarjeta al recibir",
            "payment_epay_delivery": "En línea",
            payment_online_delivery: "En línea",
            "payment_on_room_bill": "A la habitación",
            "delivery_price_included_in_total_price": "Con precio de entrega incluido {delivery_price_num} para pedidos hasta {min_price_free_delivery_num}",
            "order_type_restaurant": "En el restaurante",
            "scan_barcode": "Escanea el código QR",
            "order_type_delivery": "Entrega a domicilio",
            "order_type_preorder": "Recoger en el restaurante",
            "order_type_room": "Pedir desde la habitación",
            "order_type_served_on_spot": "Pedir para consumir en el lugar",
            "order_from_room": "Pedido desde la habitación",
            "first_and_last_name": "Nombre y Apellidos",
            "phone": "Número de teléfono",
            "order_address": "Dirección: calle Nº piso apto. ciudad",
            "delivery_time": "Fecha y hora de entrega",
            "delivery_address": "Dirección de entrega",
            "room_number": "Número de habitación",
            "table": "Mesa",
            "room": "Habitación",
            "email": "Correo electrónico",
            "remember_me": "Recuérdame",
            "promo_code": "Código promocional",
            "promo_code_placeholder": "Código promocional...",
            "apply": "Aplicar",
            "cancel": "Cancelar",
            "bill_already_wanted": "/la cuenta ya ha sido solicitada/",
            "clear": "Limpiar",
            "close": "Cerrar",
            "save": "Guardar",
            "save_and_add": "Guardar y Añadir",
            "fast_order": "Pedido rápido",
            "in_the_region_delivery": "Estoy en la región de {region}",
            "no_saved_addresses_with_that_name": "No tienes direcciones guardadas con ese nombre, añádelo desde 'Nueva dirección'",
            "choose_pickup_place": "Elige un restaurante para recoger",
            "choose_pickup_place_label": "Elige un restaurante",
            "served_on_spot": "Servido en el lugar",
            "choose_address": "Elige dirección",
            "add_new_address": "Nueva dirección",
            "choose_delivery_address": "Elige una dirección de entrega",
            "type_address": "Comience a introducir la dirección",
            "floor_and_appartment": "Piso/Apto.",
            "floor_short": "Piso",
            "appartment_short": "Apto.",
            "street_number_short": "Nº",
            "bell_short": "Timbre"
        },
        "search": {
            "searchProduct": "Buscar producto",
            "noSearchResults": "No se encontraron productos",
            "noSearchDescription": "¡Por favor cambie los criterios para encontrar el producto adecuado para usted!"
        },
        "choose_place": {
            "title": "Por favor, elige una ubicación"
        },
        "promotions": {
            "title": "Promoción"
        },
        "profile": {
            "title": "Mi perfil",
            "fullname": "Nombre y Apellidos",
            "first_name": "Nombre",
            "last_name": "Apellidos",
            "email": "Correo electrónico",
            "phone": "Teléfono",
            "address": "Dirección",
            "change_password": "Cambiar contraseña",
            "password": "Contraseña",
            "new_password": "Nueva Contraseña",
            "tabl_points": "Puntos TABL",
            "tabl_points_info": "Gana puntos TABL con cada pedido y prepárate para sorpresas increíbles ;)",
            "my_profile": "Mi perfil",
            "profile": "Perfil",
            "logout": "Cerrar sesión",
            "orders": "Mis pedidos",
            "set_waiter_table": "Asignar mesa para pedido",
            "change_waiter_table": "Cambiar mesa para pedido",
        },
        my_orders: {
            title: "Mis pedidos",
            order_date: "Fecha del pedido",
            products: "Productos",
            type: "Tipo",
            amount: "Cantidad",
            payment: "Pago",
            status: "Estado",
            address: "DIRECCIÓN",
            personal_data: "Datos personales"
        },
        "booking": {
            "book": "Reservar",
            "choose_time": "Elegir fecha y hora",
            "minutes": "min.",
            "success_booking": "Reserva exitosa. Recibirás un correo de confirmación. ¡Regístrate y podrás seguir tus reservas ;)"
        }
    },
    "orders": {
        "statuses": {
            "ordered": "Pedido",
            "cooked_spot": "Preparado",
            "served_spot": "Servido",
            "cooked_delivery": "Preparado",
            "on_the_way_delivery": "En camino",
            "cooked_preorder": "Preparado",
            "cooked_room": "Preparado",
            "paid": "Pagado",
            "rejected": "Rechazado"
        },
        paid_online: "Pagado en línea",
    },
    "common": {
        "hi": "Hola",
        "home": "Inicio",
        "free_del": "Gratis",
        "amount": "Cantidad",
        "subtotal": "Subtotal",
        "final_price": "Precio final",
        "no_internet": "Sin conexión a Internet.",
        "count": "ud.",
        "box": "caja",
        "change_place": "Cambiar de ubicación",
        "current_place": "Ubicación actual",
        "like_us": "Danos Me Gusta",
        "follow_us": "Síguenos",
        "youtube": "YouTube",
        "tipadvisor": "Tripadvisor",
        "about": "Acerca de",
        "about_us": "Sobre nosotros",
        "discount": "Descuento",
        "personal_discount": "Descuento personal",
        "discount_promocode": "Descuento Código Promocional",
        "delivery": "Entrega",
        "book": "Reservar",
        "language": "Idioma",
        "navigate_me": "Guíame",
        "navigation": "Navegación",
        "search": "Buscar",
        "call_us": "Llámanos",
        "options": "Opciones",
        "open": "Abrir",
        "product": "producto",
        "products": "productos",
        "user": "Usuario",
        "choose": "Por favor elige",
        "comment": "Comentario",
        "contacts": "Contactos",
        "working_hours": "Horario de trabajo",
        "payment_methods": "Métodos de pago",
        "gallery": "Galería",
        "change_table": "Cambiar de mesa",
        "clear_cart": "Vaciar carrito",
        "modify_ingredients": "Modificar ingredientes",
        "about_page_facilities": "Instalaciones",
        "about_page_household": "Hogar",
        "about_page_technical_support": "Soporte técnico",
        "boxes_prices": "Caja",
        "refund_policy": "Política de reembolso",
        "date": {
            "today": "Hoy",
            "tommorow": "Mañana",
            "day": {
                "1": "Lunes",
                "2": "Martes",
                "3": "Miércoles",
                "4": "Jueves",
                "5": "Viernes",
                "6": "Sábado",
                "7": "Domingo"
            }
        },
        "action": {
            "send": "Enviar",
            "skip": "Saltar",
            "back": "Atrás"
        },
        "label": {
            "describe_here": "Describa aquí"
        },
        "price_with_discount": "Precio con descuento",
        "subscribe_for_lunch_menu": "Suscríbete al menú de almuerzo de TABL",
        "cart": "Carrito",
        "profile": "Perfil",
        "login_or_register": "Iniciar sesión / Registrarse",
        "close": "Cerrar",
        "terms_for_use": "Términos de uso",
        "privacy_policy": "Política de privacidad",
        "i_read_and_agree_with": "He leído y estoy de acuerdo con ",
        "i_agree_with": "Estoy de acuerdo con",
        "currency_bgn": " lv.",
        "add_btn": "Añadir",
        "order_comment": "Comentario sobre el pedido",
        "order_modal_agree_with": "Al hacer un pedido, aceptas nuestros ",
        "and": " y",
        "cutleries": "Cubiertos",
        save: "Ahorrar"

    },
    "messages": {
        "notifications": {
            "waiter_notified_successfully": "El camarero ha sido notificado con éxito",
            "order_sent_success": "Su pedido ha sido aceptado con éxito.",
            "feedback_thanks": "¡Gracias por tu tiempo!",
            "bill_wanted_success": "La cuenta ha sido solicitada con éxito.",
            "user_need_success": "Su solicitud ha sido recibida y está siendo procesada",
            "create_order_first": "Primero debes hacer un pedido",
            "bill_already_wanted": "La cuenta ya ha sido solicitada",
            "reset_password_success": "Si existe una cuenta con ese correo electrónico, recibirás instrucciones para cambiar tu contraseña :)"
        },
        "errors": {
            "invalid_privacy_policy_and_terms": "Debes aceptar nuestra política de privacidad y los términos y condiciones",
            "invalid_privacy_policy": "Debes aceptar nuestra política de privacidad",
            "invalid_terms": "Debes aceptar los términos y condiciones",
            "invalid_email": "Por favor ingrese un correo electrónico válido",
            "invalid_password": "Por favor ingrese una contraseña válida",
            "invalid_paddword_min": "Por favor ingrese una contraseña de al menos {value} caracteres",
            "invalid_name": "Por favor ingrese un nombre válido (de 3 a 40 caracteres)",
            "invalid_phone": "Por favor ingrese un número de teléfono válido",
            "invalid_phone_min": "Por favor ingrese un número de teléfono de al menos {value} caracteres",
            "invalid_address": "Por favor ingrese una dirección válida",
            "invalid_regionId": "Por favor elige una región",
            "invalid_orderDate": "Por favor ingrese una fecha de entrega",
            "invalid_orderTime": "Por favor ingrese una hora de entrega",
            "invalid_room_number": "Por favor ingrese un número de habitación",
            "invalid_payment_method": "Por favor elige un método de pago",
            "invalid_cutlery_selected": "Por favor elige si deseas cubiertos",
            "invalid_additional_order_type": "Por favor elige un tipo de pedido",
            "invalid_text_comment": "Por favor ingrese un comentario válido de hasta 500 caracteres",
            "invalid_fields": "Por favor complete todos los campos correctamente",
            "order_sent_fail": "¡Por favor, escanee de nuevo!",
            "bill_wanted_fail": "¡Ups, no se pudo solicitar la cuenta!",
            "user_need_fail": "Ups, hubo un error al enviar la solicitud",
            "Too many requests, please try again later.": "Has alcanzado el límite de solicitudes por el momento. Por favor, inténtalo más tarde",
            "Invalid phone": "Teléfono inválido",
            "Invalid name": "Nombre inválido",
            "error": "Error",
            "invalid_pickup_place": "Por favor elige un lugar para recoger",
            "invalid_street_number": "Falta el número de calle/edificio",
            "invalid_street_number_length": "El número es demasiado largo",
            "invalid_floor_number": "Falta el número de piso",
            "invalid_floor_number_length": "El número es demasiado largo",
            "invalid_ap_number": "Falta el número de apartamento",
            "invalid_ap_number_length": "El número es demasiado largo",
            "invalid_bell_number": "Falta el número del timbre",
            "invalid_bell_number_length": "El número es demasiado largo",
            invalid_required_checkbox_in_order_modal: "Por favor, acepta si deseas continuar.",
            invalid_required_checkbox_in_order_modal_2: "Por favor, acepta si deseas continuar."
        }
    },
    "modals": {
        "upselling_products": {
            "title": "¿Por qué no pruebas también?"
        },
        language_modal: {
            back_to_default_lang: "Volver al idioma predeterminado",
            translations_may_not_be_accurate: "Es posible que el texto traducido no sea completamente exacto."
        },
        initial_html_banner: {
            title: "Bienvenido",
            to_the_menu: "Al menú"
        }
    },
    "promocode": {
        "others_discounts_are_not_applied": "Al usar este código promocional, todos los demás descuentos (si los hay) NO se aplican"
    },
    "payments": {
        "choose_payment_method": "Método de pago",
        "processing": "Procesando el pago"
    },
    "login": {
        "log_in_your_profile": "Inicia sesión en tu perfil",
        "save_your_data": "y guarda tus datos",
        "login_btn": "Iniciar sesión",
        "register_if_no_profile": "¿No tienes una cuenta? Regístrate",
        "forgot_password": "¿Olvidaste tu contraseña?"
    },
    "register": {
        "register": "Regístrate",
        "collect_tabl_points": "y acumula puntos TabL",
        "register_btn": "Regístrate",
        "already_have_account": "¿Ya tienes una cuenta? Inicia sesión"
    },
    "forgot_password": {
        "forgot_password": "Contraseña olvidada"
    },
    accumulated_order_amount: {
        use: "Use",
        remove: "Remove",
        register_for_discount: "Regístrate y gana dinero con cada pedido",
        you_have_accumulated: "Has acumulado",
        no_accumulated_money_text: "Ordene y ahorre en su próximo pedido",
        register_and_accumulate: "Regístrate y gana",
        for_you_next_order: "para tu próximo pedido",
        complete_your_order_and_accumulate: "Completa tu pedido y gana",
        profile_text: "Dinero acumulado de pedidos",
        profile_description: "Con cada pedido se acumula un % del importe en tu cuenta. Puedes usarlo en tu próxima compra :)"
    }
}